<template>
  <a-modal v-model:visible="visible" width="700px" title="关键词编辑/申请" :footer="null" >
   
    <a-form ref="formRef" :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
       <!-- 通用字段 -->
      <a-form-item  required label="发布平台">
          <a-select
          :disabled="formState.form_type=='edit'"
          v-model:value="formState.platform"
          @change="onChangePlatform"
          >
          <a-select-option :value='i' v-for='i of PlatformForApplyEnum' :key='i' >
            {{i}}
          </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item  required label="推广类型">
          <a-select
          :disabled="formState.form_type=='edit'"
          v-model:value="formState.promotion_type"
          @change="onChangePromotionType"
          >
          <a-select-option value='公域视频'>公域视频</a-select-option>
          
          <a-select-option value='直播' :disabled="['七猫'].indexOf(formState.platform) > -1">直播</a-select-option>
          <a-select-option value='私域' :disabled="['七猫'].indexOf(formState.platform) > -1">私域</a-select-option>
          <!-- <a-select-option :value='i' v-for='i of PromotionTypes' :key='i' >
            {{i}}
          </a-select-option> -->
          </a-select>
      </a-form-item>
      <a-form-item  required label="推广凭证" v-if="formState.promotion_type=='直播'||formState.promotion_type=='私域'">
        <UploadMultiple :length="4" v-model:imageUrl="formState.image_url"  />
      </a-form-item>
      <a-form-item v-if="formState.platform !='迅雷网盘链接' && formState.platform !='抖音故事锚点' && formState.platform !='百度网盘'" required  label="关键词">
          <a-input :disabled="disabled_edit" v-model:value="formState.text" />
      </a-form-item>

      <template  v-if="['百度网盘'].indexOf(formState.platform) > -1">
        <a-form-item  required label="百度网盘报备成功截图">
          <UploadMultiple :length="4" v-model:imageUrl="formState.sign_proof"  />
        </a-form-item>
        <a-form-item required  label="网盘UK">
          <a-input :disabled="disabled_edit" v-model:value="formState.text" />
        </a-form-item>
        <a-form-item required label="手机号">
          <a-input v-model:value="formState.mobile" />
        </a-form-item>
        <a-form-item  required label="网盘链接">
          <a-input type="url" placeholder="http(s)://"  v-model:value="formState.book_url"/>
        </a-form-item>
      </template>

      <template  v-if="['迅雷加速器'].indexOf(formState.platform) > -1">
        <a-form-item required label="数字账号">
          <a-input v-model:value="formState.account_id" />
        </a-form-item>
        <a-form-item required label="手机号">
          <a-input v-model:value="formState.mobile" />
        </a-form-item>
      </template>
      <template  v-if="['悟空搜索'].indexOf(formState.platform) > -1">
        <a-form-item required label="干预方式">
            <a-select
            :disabled="disabled_edit"
            v-model:value="formState.intervene"
            >
            <a-select-option :value='v' v-for='v of InterveneWUkong' :key='v' >
              {{ v }}
            </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item required   label="品类">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForWukong' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required   label="站点" v-if="formState.intervene=='单本'">
          <a-input :disabled="disabled_edit" v-model:value="formState.book_name" />
        </a-form-item>
        <a-form-item required   label="推广平台">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.remark"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForWukongPlatform' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      
      
      <a-form-item v-else-if="formState.platform =='抖音故事锚点'" required  label="抖音 UID">
          <a-input :disabled="disabled_edit" v-model:value="formState.text" />
        </a-form-item>
      <a-form-item v-if="[ 
        UserRoleOperator,
        UserRoleDirectorManager,
        UserRoleDirector,
        UserRoleAuditor,].indexOf(user_role)  > -1"  required label="博主">
        <span style="color: red;" v-if="formState.status == 1" >(已通过词只能修改博主) </span>
        <a-select
          v-if="formState.form_type=='new' || [0, 1].indexOf(formState.status) > -1" 
          v-model:value="formState._blogger_ids"
          :options="bloggerSuggestion"
          @search="searchBloggers"
          @change="onChangeBloggers"
          mode="multiple"
          placeholder="博主名称"
        />
        <a-input disabled v-else v-model:value="formState.blogger_name"/>
      </a-form-item>
       <!-- 通用字段 -->

      <a-form-item  v-if="use_books" required label="图书">
        <a-select
          v-if="formState.form_type=='new'" 
          v-model:value="formState._book_ids"
          :options="booksSuggestion"
          @search="searchBooks"
          @change="onChangeBooks"
          mode="multiple"
          placeholder="图书"
        />
        <a-input disabled v-else v-model:value="formState.book_name"/>
         <p v-if="formState.form_type == 'edit' && formState.copyright != ''">版权: {{formState.copyright}}</p>
      </a-form-item>



      <a-form-item v-if="formState.platform=='小红书'"  required label="网页 URL">
        <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
      </a-form-item>

      <a-form-item v-if="formState.platform=='迅雷小说'"  required label="迅雷网盘UID">
        <a-input :disabled="disabled_edit" placeholder="迅雷网盘UID" v-model:value="formState.account_id" show-count />
      </a-form-item>
      <a-form-item required v-if="formState.platform=='UC'"  label="干预方式（UC）">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.intervene"
          >
          <a-select-option :value='v.value' v-for='v of ConstIntervene' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item required  v-if="['夸克', '夸克搜索动漫', '夸克影视综'].indexOf(formState.platform) > -1"  label="干预方式（夸克）">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.intervene"
          >
          <a-select-option :value='v.value' v-for='v of ConstInterveneQuark' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
      </a-form-item>

      <a-form-item required v-if="formState.platform=='UC动漫'"  label="干预方式">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.intervene"
          >
          <a-select-option :value='v.value' v-for='v of ConstInterveneUCCartoon' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
      </a-form-item>
      <template  v-if="['UC网址分享'].indexOf(formState.platform) > -1">
        <a-form-item  required label="手机号">
          <a-input type="number" :disabled="disabled_edit" v-model:value="formState.mobile"/>
        </a-form-item>
         <a-form-item required   label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForUCDisk' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <template  v-if="['UC网盘'].indexOf(formState.platform) > -1">
        <a-form-item  required label="UCID">
          <a-input v-model:value="formState.sign_id"/>
        </a-form-item>
        <a-form-item  required label="UC网盘报备成功截图">
          <UploadMultiple :length="1" v-model:imageUrl="formState.sign_proof"  />
        </a-form-item>
      </template>

      <template  v-if="['夸克网盘', 'UC网盘', '迅雷网盘'].indexOf(formState.platform) > -1">
        <a-form-item  required label="迅雷网盘ID" v-if="['迅雷网盘'].indexOf(formState.platform) > -1" >
          <a-input type="number" :disabled="disabled_edit" v-model:value="formState.account_id"/>
        </a-form-item>
        <a-form-item  required :label="formState.platform === '迅雷网盘'? '迅雷绑定手机号' : '手机号'">
          <a-input type="number" :disabled="disabled_edit" v-model:value="formState.mobile"/>
        </a-form-item>
        <a-form-item  required label="网盘链接">
          <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://"  v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item  v-if="formState.platform == '夸克网盘'" required   label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategory' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
         <a-form-item v-else required   label="作品类型">
          <template v-if="formState.platform == 'UC网盘'">
            <a-select
            :disabled="disabled_edit"
            v-model:value="formState.content_category"
            >
            <a-select-option :value='v.value' v-for='v of ContentCategoryForUCDiskOnly' :key='v.value' >
              {{ v.value }}
            </a-select-option>
            </a-select>
          </template>
          <template v-else>
            <a-select
            :disabled="disabled_edit"
            v-model:value="formState.content_category"
            >
            <a-select-option :value='v.value' v-for='v of ContentCategoryForUCDisk' :key='v.value' >
              {{ v.value }}
            </a-select-option>
            </a-select>
          </template>
        </a-form-item>
      </template>
      <template  v-if="['UC影漫综'].indexOf(formState.platform) > -1">
        <a-form-item required label="干预方式">
            <a-select
            :disabled="disabled_edit"
            v-model:value="formState.intervene"
            >
            <a-select-option :value='v.value' v-for='v of ConstInterveneUCYSZ' :key='v.value' >
              {{ v.value }}
            </a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item  required label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForUCYSZ' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  required label="作品名称">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_name"/>
          </a-form-item>

        <template  v-if="UC_INTERVENE_SC == formState.intervene">
          <a-form-item  label="作者名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_author"/>
          </a-form-item>
        </template>

        <template  v-else-if="formState.intervene==UC_INTERVENE_NO_SC">
          <a-form-item  required label="作者名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_author"/>
          </a-form-item>
          <a-form-item  required label="网页 URL">
            <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
          </a-form-item>
          <a-form-item  required label="原文第一句">
            <a-textarea :disabled="disabled_edit"  v-model:value="formState.content_summary"/>
          </a-form-item>
        </template>
        
      </template>
      <template v-if="['夸克'].indexOf(formState.platform) > -1" >
        <a-form-item  required label="产品">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryQuark' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      
      <template v-if="['UC', '夸克', '夸克搜索动漫', '夸克影视综'].indexOf(formState.platform) > -1" >
        <template  v-if="UC_INTERVENE_SC == formState.intervene">
          <a-form-item  required label="书名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_name"/>
          </a-form-item>
          <a-form-item  label="作者名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_author"/>
          </a-form-item>
        </template>

        <template  v-else-if="formState.intervene==UC_INTERVENE_NO_SC">
          <a-form-item  required label="书名">
            <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
          </a-form-item>
          <a-form-item  required label="作者名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_author"/>
          </a-form-item>
          <a-form-item  required label="网页 URL">
            <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
          </a-form-item>
          <a-form-item  required label="原文第一句">
            <a-textarea :disabled="disabled_edit"  v-model:value="formState.content_summary"/>
          </a-form-item>
        </template>
        <template  v-else-if="formState.intervene==UC_INTERVENE_URL">
          <a-form-item :disabled="disabled_edit"  required label="书 URL">
            <a-input  type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
          </a-form-item>
        </template>
      </template>
      <template v-if="['UC动漫'].indexOf(formState.platform) > -1" >
        <template  v-if="UC_INTERVENE_SC == formState.intervene">
          <a-form-item  required label="动漫全名">
            <a-input :disabled="disabled_edit"  v-model:value="formState.book_name"/>
          </a-form-item>
        </template>

        <template  v-else-if="formState.intervene==UC_INTERVENE_NO_SC">
          <a-form-item  required label="动漫全名">
            <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
          </a-form-item>
          <a-form-item  required label="网页链接">
            <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
          </a-form-item>
          <a-form-item  required label="简介或描述">
            <a-textarea :disabled="disabled_edit"  v-model:value="formState.content_summary"/>
          </a-form-item>
        </template>
      </template>
      
      <template  v-if="['书旗'].indexOf(formState.platform) > -1">
        <a-form-item  required label="书名">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item :disabled="disabled_edit"  required label="书 URL">
          <a-input  type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
        </a-form-item>
      </template>
      <template  v-if="['QQ阅读'].indexOf(formState.platform) > -1">
        <a-form-item  required label="书名">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item :disabled="disabled_edit"  required label="书 URL">
          <a-input  type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item  required label="书 ID">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_id"/>
        </a-form-item>
      </template>


      <template  v-if="['起点'].indexOf(formState.platform) > -1">
        <a-form-item  required label="书 ID">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_id"/>
        </a-form-item>
         <a-form-item required   label="书类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForQidian' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  required label="书名">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item :disabled="disabled_edit"  required label="书 URL">
          <a-input  type="url" placeholder="http(s)://"   v-model:value="formState.book_url"/>
        </a-form-item>
      </template>

      <template  v-if="formState.platform == '百度'">
        <a-form-item  required label="书名">
          <a-input  :disabled="disabled_edit" v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item  required label="作者名">
          <a-input :disabled="disabled_edit"  v-model:value="formState.book_author"/>
        </a-form-item>
      </template>

      <template  v-if="['咚漫'].indexOf(formState.platform) > -1">
        <a-form-item  required label="作品名">
          <a-input  :disabled="disabled_edit"  v-model:value="formState.book_name"/>
        </a-form-item>
        <a-form-item  required label="作品链接">
          <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://" v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item required   label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForDongMan' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <template  v-if="['迅雷网盘链接'].indexOf(formState.platform) > -1">
        <a-form-item  required label="手机号">
          <a-input type="number"  :disabled="disabled_edit"  v-model:value="formState.mobile"/>
        </a-form-item>
        <a-form-item  required label="网盘链接">
          <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://" v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item required   label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForUCDisk' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>
      <template  v-if="['哔哩哔哩'].indexOf(formState.platform) > -1">
        <a-form-item  required label="手机号">
          <a-input type="number"  :disabled="disabled_edit"  v-model:value="formState.mobile"/>
        </a-form-item>
        <a-form-item  required label="UP主页链接">
          <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://" v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item required   label="作品类型">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryForBiliBili' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item  required label="UID/BV">
          <a-input :disabled="disabled_edit" v-model:value="formState.account_id"/>
        </a-form-item>
      </template>
      <template  v-if="['头条极速版网盘','悟空网盘'].indexOf(formState.platform) > -1">
        <a-form-item  required label="手机号">
          <a-input type="number"  :disabled="disabled_edit"  v-model:value="formState.mobile"/>
        </a-form-item>
        <a-form-item  required label="网盘链接">
          <a-input :disabled="disabled_edit" type="url" placeholder="http(s)://" v-model:value="formState.book_url"/>
        </a-form-item>
        <a-form-item  required label="网盘 UID">
          <a-input :disabled="disabled_edit" placeholder="UID" v-model:value="formState.book_name" show-count />
        </a-form-item>
        <a-form-item required   label="作品类型" v-if="['头条极速版网盘'].indexOf(formState.platform) > -1">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryToutiaoFastPan' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item required   label="作品类型" v-if="['悟空网盘'].indexOf(formState.platform) > -1">
          <a-select
          :disabled="disabled_edit"
          v-model:value="formState.content_category"
          >
          <a-select-option :value='v.value' v-for='v of ContentCategoryWukongFastPan' :key='v.value' >
            {{ v.value }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </template>

       <a-form-item v-if="[3].indexOf(user_role) == -1" required  label="商务">
           <a-select
           v-if="formState.form_type == 'new'"
          v-model:value="formState._director_ids"
          :options="directorSuggestion"
          @search="searchDirectors"
          @change="onChangeDirectors"
          mode="multiple"
          placeholder="商务名称"
        />
        <a-input disabled v-else v-model:value="formState.director_name"/>
      </a-form-item>

      <!-- 通用字段 -->
      <a-form-item label="备注"  v-if="!(['悟空搜索'].indexOf(formState.platform) > -1)">
          <a-input :disabled="disabled_edit" v-model:value="formState.remark" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 6 }" >
        <a-button v-if="formState.form_type == 'edit'" type="primary" html-type="submit" >编辑</a-button>
        <a-button v-else type="primary" html-type="submit" >新建</a-button>
        <a-button style="margin-left:40px" @click="onCancle">取消</a-button>
      </a-form-item>
       <!-- 通用字段 -->
    </a-form>
   
  </a-modal>
 
</template>

<script >
import { defineComponent, reactive,ref, watch} from 'vue';
import UploadMultiple from '@/components/UploadMultiple.vue'
import  { toRefs} from 'vue';
import {
  UserRoleMap, PlatformForApplyEnum, UseBooks, PromotionTypes,
  ContentCategory, 
  ContentCategoryForWukong,
  ContentCategoryForWukongZhandian,
  ContentCategoryForWukongPlatform,
  ContentCategoryForUCDisk, 
  ContentCategoryForUCDiskOnly,
  ContentCategoryForBiliBili,
  ContentCategoryForDongMan,
  ContentCategoryForQidian,
  ContentCategoryForUCYSZ, ContentCategoryQuark,
  ContentCategoryToutiaoFastPan,
  ContentCategoryWukongFastPan,
  InterveneWUkong,
} from '@/utils/macro'
import request from '@/utils/request'
import { message } from 'ant-design-vue';
import { useStore } from 'vuex'
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
} from '@/utils/macro'

let _searchBooksDebounce, _searchBloggerDebounce, _searchDirectorDebounce
let UC_INTERVENE_SC = "普通干预"
let UC_INTERVENE_NO_SC = "强制干预第一位"
let UC_INTERVENE_URL = "网页拦截"
const initialForm = {
      form_type: 'new',
      platform: '七猫',
      text: '',
      book_id: '',
      blogger_id: '',
      intervene: '',
      book_url: '',
      remark: '',
      director_id: '',
      content_category: '',

      book_author: '',
      book_name: '',
      content_summary: '',

      director_name: '',

      _book_ids: [],
      _blogger_id: [],
      _director_ids: [],

      image_url: [],
    }
export default defineComponent({
  props: {
    record: Object
  },
  components: {
    UploadMultiple,
  },
  data(){
    return {
      ContentCategory,
      UserRoleMap,
      UC_INTERVENE_SC,
      UC_INTERVENE_NO_SC,
      UC_INTERVENE_URL,
      booksSuggestion: [],
      bloggerSuggestion: [],
      directorSuggestion: [],

      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      formState: {
        ...initialForm,
      }
     
    }
  },
  computed:{
    use_books(){
      return !!UseBooks[this.formState.platform]
    },
    user_role: () => {
      const store = useStore()
      console.log('useruser', store.state.user);
      return store.state.user.role
    },
    user_id: () => {
      const store = useStore()
      console.log(store.state.user.user_id);
      return store.state.user.user_id
    },
    has_intervene() {
      return this.formState.platform == "UC" || this.formState.platform == "夸克" || this.formState.platform == '夸克搜索动漫' || this.formState.platform == '夸克影视综'
    },
    // 不是 待审核、已拒绝 的禁止编辑
    disabled_edit(){
      return [0, 2].indexOf(this.formState.status) == -1 && this.formState.form_type== 'edit'
    }
  },
  mounted(){
    // this.searchBooks()
    if (this.user_role != 4) {
      this.searchBloggers()
    }
    
    this.searchDirectors()
  },
  watch:{
    record: function(v1, v2) {
      console.log(v1);
      this.formState = {
        ...initialForm,
        ...v1,
      }
      if (v1.form_type == 'edit') {
        this.bloggerSuggestion = [
          {
            value: v1.blogger_name,
            id: v1.blogger_id,
          }
        ]
        console.log(JSON.stringify(this.bloggerSuggestion))
        this.formState._blogger_ids = [v1.blogger_name]
        this.formState.image_url = v1.images
        this.formState.sign_proof = [v1.sign_proof]
      }
      this.booksSuggestion= []
      // this.bloggerSuggestion= []
      // this.directorSuggestion= []
    }
  },
  methods: {
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._director_ids = [this.formState._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.director_id = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangeBloggers(item, options){
      console.log(options);
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._blogger_ids = [this.formState._blogger_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.blogger_id = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
        request.get(`/api/bloggers/suggestion`, {
          blogger_name: bloggerName,
        }).then((rsp) => {
          this.bloggerSuggestion = []
          for (let v of rsp.data.suggestion) {
            let value = v.director_name?`${v.username} (${v.director_name})`:v.username
            this.bloggerSuggestion.push({
                value,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangePlatform(){
      this.booksSuggestion = []
      this.formState.promotion_type = '公域视频'
    },
    onChangePromotionType() {
      console.log(1);
    },
    searchBooks(bookName) {
      clearTimeout(_searchBooksDebounce)
      _searchBooksDebounce = setTimeout(() => {
        request.get(`/api/books`, {
          book_name: bookName,
          platform: this.formState.platform
        }).then((rsp) => {
          this.booksSuggestion = []
          for (let v of rsp.data.list) {
            this.booksSuggestion.push({
              value: v.book_name + '-' + v.author,
              id: v.id
            })
          }
        })
      }, 200)
    },
    onChangeBooks(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formState._book_ids = [this.formState._book_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formState.book_id = ids.join(',')
    },
    async onSubmit(){
      
      let payload = {
        ...this.formState,
        book_id: +this.formState.book_id,
        blogger_id: +this.formState.blogger_id,
        director_id: +this.formState.director_id,
        sign_proof: this.formState.sign_proof ? this.formState.sign_proof[0] : ''
      }
      console.log('payload',payload,this.user_role);
      // if (this.user_role==3) {
      //   console.log(+this.userId);
      //   payload['blogger_id']=+this.userId
      // }
      
      // return

      if (this.formState.promotion_type=='直播'||this.formState.promotion_type=='私域') {
        if (!this.formState.image_url.length) return message.error("请上传推广凭证")
        payload['images'] = this.formState.image_url
      }
      for (let k in payload) {
        if (typeof payload[k] == 'string') {
          payload[k] = payload[k].trim()
        }
      }

      if (!payload.platform ) {
        return message.error("请填写平台")
      }
      if (payload.platform != '迅雷网盘链接' && !payload.text ) {
        return message.error("请填写关键词")
      }
      if (this.user_role == 3 && !payload.blogger_id) {
        return message.error("请填写商务")
      }

      if (this.formState.form_type == 'edit') {
        await request.put(`/api/keywords/${this.record.id}`, payload)
      } else {

        if ((payload.platform == '头条极速版网盘'||payload.platform == '悟空网盘') && !/^\d{1,16}$/.test(this.formState.book_name)) {
          return message.error("请输入最多16位数字的网盘 UID")
        }

        await request.post('/api/keywords', payload)
      }
     
      this.$emit("ok")
    },
    onCancle: function () {
      this.$emit("cancel", false)
    },
  },
  setup(props, context) {
    const { visible, record } = toRefs(props)
    const store = useStore()
    console.log('useruser', store.state.user);
    let userId=store.state.user.user_id
    return { 
      userId,
      UserRoleOperator,
      UserRoleDirectorManager,
      UserRoleDirector,
      UserRoleBlogger,
      UserRoleAuditor,
      PlatformForApplyEnum,
      InterveneWUkong,
      ContentCategoryForWukong,
      ContentCategoryForWukongZhandian,
      ContentCategoryForWukongPlatform,
      PromotionTypes,
      ContentCategoryForUCDisk,
      ContentCategoryForUCDiskOnly,
      ContentCategoryForBiliBili,
      ContentCategoryForDongMan,
      ContentCategoryForQidian,
      ContentCategoryForUCYSZ,
      ContentCategoryQuark,
      ContentCategoryToutiaoFastPan,
      ContentCategoryWukongFastPan,
      ConstIntervene: [
        {
          value: UC_INTERVENE_SC, label: '关键词、书名、作者',
        },
        {        
          value: UC_INTERVENE_NO_SC, label: '关键词、书名、作者、原链接、原文第一句话',
        },
        {
          value: UC_INTERVENE_URL, label: '关键词、原链接',
        }
      ],
      ConstInterveneQuark: [
        {
          value: UC_INTERVENE_SC, label: '关键词、书名、作者',
        },
        {        
          value: UC_INTERVENE_NO_SC, label: '关键词、书名、作者、原链接、原文第一句话',
        }
      ],ConstInterveneUCCartoon: [
        {
          value: UC_INTERVENE_SC, label: '动漫全名',
        },
        {        
          value: UC_INTERVENE_NO_SC, label: '动漫全名、网页地址、描述',
        }
      ],ConstInterveneUCYSZ: [
        {
          value: UC_INTERVENE_SC, label: '作品名、作者',
        },
        {        
          value: UC_INTERVENE_NO_SC, label: '作品名、作者、原链接、原文第一句话',
        }
      ],
      visible
    };
  },
});
</script>