<template>
  <div style="display:flex;flex-wrap:wrap;">
    <!-- <img :src="item" v-for="item in imageUrl" :key="item" alt="avatar" style="width:100px;max-height:100px;margin-left:10px" /> -->
    <a-upload
      v-model:file-list="fileList"
      :customRequest="handleUpload"
      :remove="handleRemove"
      list-type="picture-card"
      style="width:100px;height:100px;"
    >
      <div v-if="fileList.length < length">
        <plus-outlined />
        <div style="margin-top: 8px">点击上传</div>
      </div>
    </a-upload>
  </div>
</template>
<script>

import { defineComponent, reactive, ref } from 'vue'
import request from '@/utils/request'
import { Modal, message } from 'ant-design-vue';
import OSS from 'ali-oss'

export default defineComponent({
  props: {
    imageUrl: {
      type: Array,
      default() {
        return []
      }
    },
    length: {
      type: Number,
      default: 4
    }
  },
  data(){
    return {
      fileList: [],
      loadingImage: false,
    }
  },
  watch: {
    imageUrl(newVal) {
      if (newVal) {
        this.formatFile()
      }
    },
  },
  mounted() {
    this.formatFile()
  },
  methods: {
    formatFile() {
      if (!this.fileList.length) return
      this.fileList = this.imageUrl.map(v=>{
        let obj = {
          uid: new Date().getTime(),
          name: new Date().getTime(),
          url: v,
          status: 'done', // 文件状态，'done'表示文件已上传完成
          response: 'success', // 服务器响应内容，一般为字符串或对象
        }
        return obj
      })
    },
    async handleUpload(file) {
      if (!file.file) {
          message.error("请选择文件")
          return
      }
      this.loadingImage = true
      const sts = await request.post(`/api/aliyun/sts`, {})
      if (sts.error) {
        message.error(`存储服务授权失败:` + sts.error.message)
        return
      }
      const client =  new OSS({
          accessKeyId: sts.data.AccessKeyId,
          accessKeySecret: sts.data.AccessKeySecret,
          stsToken: sts.data.SecurityToken,
          bucket: 'applet-promotion',
          region: 'oss-cn-beijing'
      });
      const name = `/keyword-image/${file.filename}-${Date.now()}.${file.file.type.split('/')[1]}`
      const result = await client.multipartUpload(name, file.file);
      var url = "http://applet-promotion.oss-cn-beijing.aliyuncs.com" // 待定
      if (result && result.name) {
          url = url + result.name
      }
      // this.imageUrl = url
      let urlList = [...this.imageUrl]|| []
      // this.fileList.push({name: new Date().getTime(), url, uid: new Date().getTime(),  status: 'done', response: 'success', })
      urlList.push(url)
      console.log('urlList', urlList,this.fileList);
      this.fileList[this.fileList.length-1].url=url
      this.fileList[this.fileList.length-1].status='success'
      this.$emit('update:imageUrl', urlList)
      this.loadingImage = false
    },
    handleChangeUpload({file, fileList}) {
      console.log('change', file, fileList)
      // this.fileList = fileList
    },
    handleRemove(file) {
      console.log('remove', file)
      const index = this.fileList.indexOf(file)  
      const newFileList = this.fileList.slice()  
      newFileList.splice(index, 1)  
      this.fileList = newFileList 
      let list = this.fileList.map(v=>v.url)
      console.log(this.fileList, list);
      this.$emit('update:imageUrl', list)
      // this.fileList = fileList
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('Image must smaller than 10MB!');
      }
      return isJpgOrPng && isLt2M;
    },
  },
})
</script>
<style lang='scss' scoped>

</style>