<template>
  <a-card title="UK上下级">
    <a-space>
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;"   >
        <a-form-item label="UK">
          <a-input placeholder="UK" v-model:value="formSearch.uk" />
        </a-form-item>
<!--        
        <a-form-item label="时间">
          <a-date-picker
            v-model:value="formSearch.time_range"
            :allowClear="false"
          />
        </a-form-item> -->

        <a-form-item>
          <a-button type="primary" @click="handleTableChange" shape="circle"><template #icon><SearchOutlined /></template></a-button>
        </a-form-item>
      </a-form>

    </a-space>

    <a-row style="margin-bottom:10px">
      <a-button type="primary" @click="handleExport">导出</a-button>
    </a-row>
    
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />

    <a-table 
    class="ant-table-striped"
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :loading="loading"
    :pagination="false"
    :scroll="{ x: 800 }"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <!-- <template #detail="{ record }">
        <div style="max-height: 200px;overflow: auto;"><pre style="white-space: pre-wrap;">{{ record.detail }}</pre></div>
      </template> -->
      
      <template #detail="{ record , column }">
        <div>{{ record.detail[column.key] }}</div>
      </template>

    </a-table>

    <div style="width:100%;text-align:right;margin-top:20px" v-if="hasMore">
      <a-button type="primary" @click="handleTableChange">下一页</a-button>
    </div>
  </a-card>

</template>
<script>
import { defineComponent, reactive, ref, h } from 'vue'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import request from '@/utils/request'
import {  UserRoleMap, UserStatusMap, BaiduUkMap } from '@/utils/macro'
import { Modal, message } from 'ant-design-vue';
import { useStore } from 'vuex'
import moment from 'moment';



export default defineComponent({
  components: {
    SearchOutlined,
  },
  data(){
    return {
      UserRoleMap,
      loading: false,
      dataSource: [],
      noticeMessage: '',
      formSearch: {
        // time_range: moment(),
        uk: '',
      },
      pagination: {
        total: 1,
        current: 0,
        pageSize: 100,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      openDia: false,
      confirmLoading: false,
      currentData: null,
      fileList: [],
      loadingImage: false,
      imageUrl: '',
      formAdd: {
        title: '',
        image_url: '',
      },
      openPreviewDia: false,
      previewUrl: '',
      hasMore: false,
      columns: [],
    }
  },
  mounted(){
    // if (this.user_role !== 1) {
    //   return
    // }
    // this.handleTableChange()

  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  methods: {
    moment,
    handleExport() {
      window.open(`/api/baidu/acquisition/relation?is_export=1&uk=${this.formSearch.uk}`)
      return
    },
    async handleTableChange (pagi = {pageSize: 100, current: 1}) {
      this.loading = true
      try {
        this.columns = []
        const rsp = await request.get('/api/baidu/acquisition/relation', {
          offset: (pagi.current - 1) * pagi.pageSize ,
          limit: pagi.pageSize,
          ...this.formSearch,
          // settle_day: this.formSearch.time_range ? moment(this.formSearch.time_range).unix() : '',
        })
        this.loading = false
        this.dataSource = [].concat(rsp.data.result.list)
        this.hasMore = rsp.data.result.has_more !== 0
        // this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        // this.pagination.total = rsp.data.pagination.total


        let obj = this.dataSource[0];

        for(let key in obj) {
          let col = {}
          if (key!=='detail') {
            col = {
              title: key,
              dataIndex: key,
              key: key,

            }
          } else {
            // col = {
            //   title: key,
            //   dataIndex: key,
            //   key: key,
            //   slots: { customRender: key },
            //   width: 400,
            // }
            for(let k in obj[key]) {
              col = {
                title: BaiduUkMap[k],
                dataIndex: k,
                // key: obj[key][k],
                slots: { customRender: 'detail' },
                width: 150,
              }
              this.columns.push(col)
            }
          }
          this.columns.push(col)
        }


        this.pagination.current = pagi.current
        this.pagination.pageSize = pagi.pageSize
      } catch(err) {
        this.loading = false
        this.dataSource = []
        this.noticeMessage = `共 0 条`
      }
    },
    deleteActivity(initial = {}) {
      console.log(initial);
      let that = this;
      Modal.confirm({
        title: '确认删除？',
        icon: h(ExclamationCircleOutlined),
        async onOk() {
          try {
            console.log('OK');
            const rsp = await request.delete('/api/activities/'+initial.id, {
              status: initial.status,
            })
            that.handleTableChange()
            Modal.destroy();
          } catch(err) {
            console.log(err);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    openPreview(initial) {
      this.previewUrl = initial.image_url
      this.openPreviewDia = true
    },
    openActivityDia(initial=null) {
      if(initial) {
        this.currentData = {...initial}
        this.formAdd = {...initial}
      } else {
        this.currentData = null
        this.formAdd = {}
      }
      this.currentData = initial ? {...initial} : null
      this.openDia = true
    },
    handleConfirm() {
      this.$refs.formRef.validate()
      .then(async () => {
        if (!this.currentData) {
          const rsp = await request.post('/api/activities', {
            ...this.formAdd,
          })
          this.resetFresh()
        } else {
          const rsp = await request.put('/api/activities/'+this.formAdd.id, {
            ...this.formAdd,
          })
          this.resetFresh()
        }
      })
      .catch(error => {
        console.log('error', error);
      });
    },
    resetFresh() {
      this.resetForm()
      this.openDia = false;
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
  },
  setup() {
    const applyTimeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!applyTimeRange.value || applyTimeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(applyTimeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        applyTimeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      applyTimeRange.value = val;
    };
    return {
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      StatusMap: UserStatusMap,
      // columns: [
      //   {
      //     title: 'ID',
      //     dataIndex: 'id',
      //     key: 'id',
      //   },
      //   {
      //     title: '标题',
      //     dataIndex: 'title',
      //     key: 'title',
      //   },
      //   {
      //     title: '链接',
      //     dataIndex: 'redirect_url',
      //     key: 'redirect_url',
      //   },
      //   {
      //     title: '操作人',
      //     dataIndex: 'operator',
      //     key: 'operator',
      //   },
      //   {
      //     title: '添加时间',
      //     dataIndex: 'created_at',
      //     key: 'created_at',
      //     slots: { customRender: 'created_at' },
      //     width: 180,
      //   },
      //   {
      //     title: '操作',
      //     key: 'action',
      //     fixed: 'right',
      //     width: 200,
      //     slots: { customRender: 'action' },
      //   },
      // ],
    };
  },
})
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>