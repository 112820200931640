<template>

  <a-card title="商务每日投放数据">
   
    <a-space>
     <a-form :model="formSearch" :label-col=" { span: 4 }" :wrapper-col="{ span: 14}" style="margin: 10px auto;" >
        <a-form-item  label="商务">
            <a-select
            v-model:value="formSearch._director_ids"
            :options="directorSuggestion"
            @search="searchDirectors"
            @change="onChangeDirectors"
            mode="multiple"
            placeholder="商务名称"
          />
        </a-form-item>
      
       <a-form-item label="平台" >
          <a-select
            v-model:value="formSearch.platform"
            placeholder="平台"
            style="width: 200px"
          >
            <a-select-option :value='i' v-for='i of PlatformEnum' :key='i' >
              {{i}}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="统计日期" >
          <a-range-picker
            v-model:value="formSearch.time_range"
            :disabledDate="disabledDateRangeDate"
            @change="onDateRangeChange"
            @openChange="onDateRangeOpenChange"
            @calendarChange="onDateRangeCalendarChange"
          />
        </a-form-item>
      
        
      <a-form-item>
        <a-button
          type="primary"
          @click="handleTableChange()"
        >
         搜索
        </a-button>
      </a-form-item>
     
    </a-form>
    </a-space>
    
    <!-- <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  /> -->

    <a-table 
    size="small"
    class="ant-table-striped"
    :row-key="record => record.director_name"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="false"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #customTitle>
        <span>
          {{formSearch.platform=='UC网盘'?'每日转存单价':'群组其他'}}
        </span>
      </template>
      <template #role="{ record }">
        <a>{{ RoleMap[record.role] }}</a>
      </template>
       <template #status="{ record }">
        <span>
          <a-tag v-if="record.status == 1" color="green"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag v-else-if="record.status == 3" color="red"  >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag  v-else-if="record.status == 2" color="orange" >{{ KeywordStatusMap[record.status] }}</a-tag>
          <a-tag v-else   >{{ KeywordStatusMap[record.status] }}</a-tag>
        </span>
      </template>
      <template #publish_url="{ record }" >
        <a
          v-if="record.status == 3"
          @click="handleShowPublishedURL(record)"
        >
        查看
        </a>
      </template>
      <template #sum_stats="{ record }" >
        <span v-if="record.sum_stats"  >{{record.sum_stats}}</span>
      </template>
       <template #created_at="{ record }" >
        <span >{{moment(record.created_at).format('YYYY-DD-MM HH:mm:ss')}}</span>
      </template>
      
      <template #action="{ record }" >
        <span>
         
          <a-space>
            <template v-if="pageType == 'manage'" >
              <a-button
                v-if="record.status == 0 "
                type="primary"
                size="small"
                @click="onAudit(record, 1)"
              >
                通过
              </a-button>
              <a-button
                v-if="record.status == 0 "
                danger
                size="small"
                @click="onAudit(record, 2)"
              >
                拒绝
              </a-button>
              <a-divider type="vertical" />
            </template>
            <template v-if="pageType == 'apply'" >
              <a
                v-if="[1,3].indexOf(record.status) > -1"
                @click="handleShowPublishEditor(record)"
              >
              发布链接
              </a>
              <a-divider type="vertical" />
             
            </template>
          </a-space>
          <a @click="onDeleteKeyword(record)">删除 {{record.username}}</a>
          <a v-if="[0, 2].indexOf(record.status) > -1" @click="handleShowKeywordEditor('edit', record)" >编辑</a>
          <template v-if="record.sum_stats > 0"  >
            <a-divider type="vertical" />
            <a @click="handleViewKeywordStatus(record)" >查看数据</a>
          </template>
        </span>
      </template>
    </a-table>
  </a-card>
</template>
<script >
import { defineComponent, ref} from 'vue'
import { message } from 'ant-design-vue';
import _ from 'lodash'
import { useStore } from 'vuex'
import moment from 'moment'


import {UserRoleMap, KeywordStatusMap, PlatformEnum} from '@/utils/macro'
import request from '@/utils/request'


let _searchBloggerDebounce = null 
let _searchDirectorDebounce = null

let pageTypeTitle = {
  "manage": "关键词列表",
  "apply": '关键词申请',
  'stats': '关键词数据'
}

export default defineComponent({
  components: {
  },
  data(){
    return {
      moment,
      UserRoleMap,
      KeywordStatusMap,
      pageType: "manage",
      pageTitle: "关键词列表",

      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 10,
        'show-size-changer': true,
        pageSizeOptions: ['10', '20', '50', '100']
      },
      noticeMessage: '',

      formSearch: {
        query: '',
        _blogger_ids: [],
        _book_ids: [],
        _director_ids: [],
        blogger_ids: '',
        book_ids: '',
        director_ids: '',
        platform: '',
        time_range: [],
      },

      bloggerSuggestion: [],
      booksSuggestion: [],
      directorSuggestion: [],

    }
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    }
  },
  watch:{
    keywordStatus: function (v, ov){
      this.handleTableChange()
    },
    $route: {
       handler(route,oldval){
        if (route.query.type) {
          this.pageType = route.query.type
        }
        this.pageTitle = pageTypeTitle[this.pageType]

      },
      // 深度观察监听
      deep: true
    },
    'formSearch.time_range': function (v, ov){
        this.handleTableChange()
    },
  },
  mounted(){
    this.searchDirectors()
  },
  methods: {
    onChangeDirectors(item, options){
      if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._director_ids = [this.formSearch._director_ids[0]]
        return false
      }
      let ids = []
      for(let v of options) {
        ids.push(v.id)
      }
      this.formSearch.director_ids = ids.join(',')
    },
    
    searchDirectors(name) {
      clearTimeout(_searchDirectorDebounce)
      _searchDirectorDebounce =  setTimeout(() => {
        request.get(`/api/users/directors`, {
          username: name
        }).then((rsp) => {
          this.directorSuggestion = []
          for (let v of rsp.data.list) {
            this.directorSuggestion.push({
              value: v.username,
              id: v.id
            })
          }
        })
      }, 200)
    },

    onChangeBloggers(item, options){
     if (options.length > 1) {
        message.error("只能选择一项")
        this.formSearch._blogger_ids = [this.formSearch._blogger_ids[0]]
        return
      }
     let ids = []
     for(let v of options) {
       ids.push(v.id)
     }
     this.formSearch.blogger_ids = ids.join(',')
    },
    
    searchBloggers(bloggerName) {
      clearTimeout(_searchBloggerDebounce)
      _searchBloggerDebounce =  setTimeout(() => {
        request.get(`/api/bloggers/suggestion`, {
            blogger_name: bloggerName
          }).then((rsp) => {
            this.bloggerSuggestion = []
            for (let v of rsp.data.suggestion) {
              let value = v.director_name?`${v.username} (${v.director_name})`:v.username
              this.bloggerSuggestion.push({
                  value,
                id: v.id
              })
            }
          })
        }, 600)
      
    },
    
    
    async handleTableChange (pagi, filters, sorter) {
      this.loading = true
      console.log(sorter);
      let sort = sorter?.order ? `${sorter.field} ${sorter.order.slice(0, -3)}` : ''

      let timeRange = []
      for (let t of this.formSearch.time_range) {
        timeRange.push(moment([t.year(),t.month() ,t.date()]).unix())
      }
      request.get('/api/stats/stats_director_daily', {
        director_ids: this.formSearch.director_ids,
        platform: this.formSearch.platform,
        time_range: timeRange.join(','),
        order_by: sort
      }).then((rsp) => {
        this.loading = false
        this.dataSource = [].concat(rsp.data.list)
        this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
        this.pagination.total = rsp.data.pagination.total
        this.pagination.current = 0
        this.pagination.pageSize = rsp.data.pagination.total
      }).catch(() => {
        this.loading = false
      })
     
    },
  },
  setup(props, context) {
    const timeRange = ref([]);
    const momentValue = ref();

    
    const disabledDateRangeDate = (current) => {
      if (!timeRange.value || timeRange.value.length === 0) {
        return false;
      }
      const diffDate = current.diff(timeRange.value[0], 'days');
      return Math.abs(diffDate) > 60;
    };
     const onDateRangeOpenChange = (open) => {
      if (open) {
        timeRange.value = [];
      }
    };

    const onDateRangeChange = (val) => {
      momentValue.value = val;
    };

    const onDateRangeCalendarChange = (val) => {
      timeRange.value = val;
    };


    return {
      PlatformEnum,
      uploadCreateKeywordInput: null,
      uploadAuditKeywordInput: null,
      uploadPublishKeywordInput: null,
      uploadStatKeywordInput: null,
      disabledDateRangeDate,
      onDateRangeOpenChange,
      onDateRangeChange,
      onDateRangeCalendarChange,
      columns: [
        // {
        //   title: '商务',
        //   dataIndex: 'director_name',
        //   key: 'director_name',
        // },
        {
          title: '统计日期',
          dataIndex: 'stats_date',
          key: 'stats_date',
          sorter: true,
        },
         {
          title: '拉新数',
          dataIndex: 'stats_sum',
          key: 'stats_sum',
          slots: { customRender: 'stats_sum' },
          sorter: true,
        },
        //  {
        //   title: '发布数',
        //   dataIndex: 'published_count',
        //   key: 'published_count',
        //   slots: { customRender: 'published_count' },
        // },
        {
          title: '费用收益',
          dataIndex: 'profit_sum',
          key: 'profit_sum',
        },
        {
          title: '其他数据',
          dataIndex: 'extra_data_sum',
          key: 'extra_data_sum',
        },
        {
          title: '群组拉新',
          dataIndex: 'group_growth_sum',
          key: 'group_growth_sum',
        },
        {
          title: '群组付费收益',
          dataIndex: 'group_fee_profit_sum',
          key: 'group_fee_profit_sum',
        },
        {
          dataIndex: 'group_other_sum',
          key: 'group_other_sum',
          slots: { title: 'customTitle' },
        },
         
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>