<template>
  <div style="padding-bottom: 70px;">
   
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <a-sub-menu key="basic">
        <template #icon>
          <PieChartOutlined />
        </template>
        <template #title>基础信息管理</template>
        <a-menu-item key="/keywords/profile">
            
            <router-link :to="{ path: '/keywords/profile'}">
              个人设置
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[
          UserRoleOperator,
          UserRoleDirectorManager,
          UserRoleDirector,
        ].indexOf(user_role) > -1" key="/keywords/users">
            <router-link :to="{ path: '/keywords/users'}">
              用户管理
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="user_role==3&&user_label=='core'" key="/keywords/users_director_core">
            <router-link :to="{ path: '/keywords/users_director_core'}">
              普通团长管理
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleDirector,UserRoleDirectorManager, UserRoleOperator,UserRoleAuditor].indexOf(user_role) > -1"  key="/keywords/bloggers">
            <router-link :to="{ path: '/keywords/bloggers'}">
              博主管理
            </router-link>
        </a-menu-item>
        <a-menu-item   v-if="[UserRoleOperator, UserRoleDirector, UserRoleBlogger].indexOf(user_role) > -1"   key="/keywords/accounts">
            <router-link :to="{ path: '/keywords/accounts'}">
              发布账号
            </router-link>
        </a-menu-item>
        <a-menu-item   v-if="[UserRoleOperator].indexOf(user_role) > -1"   key="/keywords/bank_cards">
            <router-link :to="{ path: '/keywords/bank_cards'}">
              支付信息
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator,UserRoleAuditor].indexOf(user_role) > -1"  key="/keywords/books">
            <router-link :to="{ path: '/keywords/books'}">
              书库管理
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator,UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/resource">
            <router-link :to="{ path: '/keywords/resource'}">
              资源管理
            </router-link>
        </a-menu-item>

        <a-menu-item key="/keywords/audit"  v-if="[UserRoleOperator].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/audit'}">
              用户审核
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/platforms">
            <router-link :to="{ path: '/keywords/platforms'}">
              平台产品列表
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/hotBookLists">
            <router-link :to="{ path: '/keywords/hotBookLists'}">
              爆款书单管理
            </router-link>
        </a-menu-item>
        <!-- <a-menu-item  v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/books">
            <router-link :to="{ path: '/keywords/books'}">
              用户收款信息
            </router-link>
        </a-menu-item> -->

      </a-sub-menu>
      <a-sub-menu key="operate" v-if="[UserRoleOperator, UserRoleBlogger].indexOf(user_role) > -1">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>平台运营</template>
        <a-menu-item    key="/keywords/banner" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/banner', query: {}}">
            首页Banner管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/popups" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/popups', query: {}}">
            弹窗管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/activity" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/activity', query: {}}">
            活动页管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/secret" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/secret', query: {}}">
            秘籍管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/member" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/member', query: {}}">
            会员码管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/ucmember" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/ucmember', query: {}}">
            UC会员管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/report" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/report', query: {}}">
            举报抄袭处理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/invitation" v-if="[UserRoleOperator, UserRoleBlogger].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/invitation', query: {}}">
            裂变活动
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/baidu" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/baidu', query: {}}">
            百度网盘官方数据
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/uk" v-if="[UserRoleOperator].indexOf(user_role) > -1">
          <router-link :to="{ path: '/keywords/uk', query: {}}">
            UK上下级
          </router-link>
        </a-menu-item>
        
      </a-sub-menu>
      <a-sub-menu key="payment" v-if="[UserRoleOperator,UserRoleCaiwu].indexOf(user_role) > -1">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <!-- PaymentPlatforms -->
        <template #title>付款管理</template>
        <a-menu-item key="/keywords/payment_platforms">
          <router-link :to="{path: '/keywords/payment_platforms'}">
            税源地配置
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/payment">
          <router-link :to="{path: '/keywords/payment_manage'}">
            收入申请
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/incomes_detail">
          <router-link :to="{path: '/keywords/incomes_detail'}">
            收入明细
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/payment_batches">
          <router-link :to="{path: '/keywords/payment_batches'}">
            付款批次
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/payment_detail">
          <router-link :to="{path: '/keywords/payment_detail'}">
            付款明细
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="keywords">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>关键词信息管理</template>
        <a-menu-item  v-if="[UserRoleOperator, UserRoleAuditor, UserRoleDirector, UserRoleDirectorManager].indexOf(user_role) > -1"  key="/keywords/manage">
          <router-link :to="{ path: '/keywords/manage', query: {}}">
            关键词列表
          </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/apply">
          <router-link :to="{ path: '/keywords/apply', query: {}}">
            关键词申请
          </router-link>
        </a-menu-item>
        
      </a-sub-menu>
      <a-sub-menu key="resources"  v-if="[UserRoleOperator,UserRoleAuditor].indexOf(user_role) > -1 || daifa_perms">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>悬赏资源管理</template>
        <a-menu-item key="/resources/manage" v-if="[UserRoleOperator].indexOf(user_role) > -1 || daifa_perms">
          <router-link :to="{ path: '/resources/manage', query: {}}">
            悬赏资源管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/resources/pkgs" v-if="[UserRoleOperator].indexOf(user_role) > -1 || daifa_perms">
          <router-link :to="{ path: '/resources/pkgs', query: {}}">
            悬赏资源包管理
          </router-link>
        </a-menu-item>
        <a-menu-item key="/resources/links" v-if="[UserRoleOperator,UserRoleAuditor].indexOf(user_role) > -1">
          <router-link :to="{ path: '/resources/links', query: {}}">
            悬赏链接审核
          </router-link>
        </a-menu-item>
        
      </a-sub-menu>
      <a-sub-menu key="stats" v-if="[
        UserRoleOperator,
        UserRoleDirectorManager,
        UserRoleDirector,
        UserRoleBlogger].indexOf(user_role) > -1" >
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>数据统计</template>
         <a-menu-item  key="/keywords/stats_keyword">
            <router-link :to="{ path: '/keywords/stats_keyword'}">
              关键词每日统计
            </router-link>
        </a-menu-item>
        <a-menu-item  key="/keywords/stats_keyword_sum">
            <router-link :to="{ path: '/keywords/stats_keyword_sum'}">
              关键词累计统计
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/stats_data_blogger">
            <router-link :to="{ path: '/keywords/stats_data_blogger'}">
              博主数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[
          UserRoleOperator,
          UserRoleDirectorManager,
          UserRoleDirector,
        ].indexOf(user_role) > -1"  key="/keywords/stats_data_director_daily">
            <router-link :to="{ path: '/keywords/stats_data_director_daily'}">
              商务每日统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[
          UserRoleOperator,
          UserRoleDirectorManager,
        ].indexOf(user_role) > -1"  key="/keywords/stats_data_director">
            <router-link :to="{ path: '/keywords/stats_data_director'}">
              商务数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/stats_data_director_manager">
            <router-link :to="{ path: '/keywords/stats_data_director_manager'}">
              商务负责人数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/stats_newer_platform">
          <router-link :to="{ path: '/keywords/stats_newer_platform'}">
            平台拉新每日统计
          </router-link>
        </a-menu-item>
        <a-menu-item   v-if="[UserRoleOperator].indexOf(user_role) > -1" key="/keywords/stats_newer_platform_sum">
          <router-link :to="{ path: '/keywords/stats_newer_platform_sum'}">
            平台拉新汇总统计
          </router-link>
        </a-menu-item>
        
        <a-menu-item v-if="[
          UserRoleOperator,
          UserRoleDirectorManager,
          UserRoleDirector,
        ].indexOf(user_role) > -1"  key="/keywords/dashboard">
            <router-link :to="{ path: '/keywords/dashboard'}">
              核心指标看板
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/stats_data_upload">
            <router-link :to="{ path: '/keywords/stats_data_upload'}">
              数据上传
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/audit_record">
            <router-link :to="{ path: '/keywords/audit_record'}">
              操作记录
            </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="relatives-director" v-if="[UserRoleOperator, UserRoleDirector].indexOf(user_role) > -1">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>团长关系管理</template>
         <a-menu-item  key="/keywords/director_core/director_set" v-if="[UserRoleOperator].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/director_core/director_set'}">
              核心团长管理
            </router-link>
        </a-menu-item>
         <a-menu-item  key="/keywords/director_core/bound" v-if="[UserRoleOperator].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/director_core/bound'}">
              配置团长关系
            </router-link>
        </a-menu-item>
         <a-menu-item  key="/keywords/director_core/director">
            <router-link :to="{ path: '/keywords/director_core/director'}">
              团长汇总数据
            </router-link>
        </a-menu-item>
        <a-menu-item  key="/keywords/director_core/stats">
            <router-link :to="{ path: '/keywords/director_core/stats'}">
              拉新汇总数据
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/director_core/blogger">
            <router-link :to="{ path: '/keywords/director_core/blogger'}">
              博主明细数据
            </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="relatives-leader" v-if="[UserRoleOperator,UserRoleDirectorManager].indexOf(user_role) > -1">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>主管关系管理</template>
         <a-menu-item  key="/keywords/manager_leader/leader_set" v-if="[UserRoleOperator].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/manager_leader/leader_set'}">
              商务主管Leader管理
            </router-link>
        </a-menu-item>
         <a-menu-item  key="/keywords/manager_leader/bound" v-if="[UserRoleOperator].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/manager_leader/bound'}">
              配置主管关系
            </router-link>
        </a-menu-item>
         <a-menu-item  key="/keywords/manager_leader/summary">
            <router-link :to="{ path: '/keywords/manager_leader/summary'}">
              主管汇总数据
            </router-link>
        </a-menu-item>
        <a-menu-item  key="/keywords/manager_leader/director">
            <router-link :to="{ path: '/keywords/manager_leader/director'}">
              团长明细数据
            </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="applet">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>小程序挂载</template>
        <a-menu-item key="/applet/stats">
          <router-link :to="{ path: '/applet/stats'}">
            挂载统计
          </router-link>
        </a-menu-item>
       
        
      </a-sub-menu>
      <a-sub-menu key="notification">
        <template #icon>
          <AppstoreOutlined />
        </template>
        <template #title>通知消息</template>
        <a-menu-item key="/keywords/notification">
          <router-link :to="{ path: '/keywords/notification'}">
            消息列表
          </router-link>
        </a-menu-item>
        <a-menu-item  v-if="[UserRoleOperator].indexOf(user_role) > -1"  key="/keywords/op_notification">
          <router-link :to="{ path: '/keywords/op_notification', query: {}}">
            通知发布
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item key="/keywords/notification">
          <router-link :to="{ path: '/keywords/notification'}">
            全局弹窗消息管理
          </router-link>
        </a-menu-item> -->
        
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script >
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import {useStore} from 'vuex'
import {
  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
  CREATE_RESOURCE_PACKAGE,
  UserRoleCaiwu,
} from '@/utils/macro'

import {
  PieChartOutlined,
  AppstoreOutlined,
} from '@ant-design/icons-vue';


export default defineComponent({
  components: {
    PieChartOutlined,
    AppstoreOutlined,    
  },
  computed:{
    user_role: () => {
      const store = useStore()
      return store.state.user.role
    },
    user_label: () => {
      const store = useStore()
      return store.state.user.label
    },
    daifa_perms: () => {
      const store = useStore()
      return store.state.user.perms && store.state.user.perms.includes(CREATE_RESOURCE_PACKAGE)
    }
  },
  watch: {
    $route: {
       handler(route,oldval){
        this.selectedKeys = [route.path]
      },
      // 深度观察监听
      deep: true
    }
  },
  setup() {
    let route = useRoute()
    const state = reactive({
      collapsed: false,
      selectedKeys: [route.path],
      openKeys: [route.meta.key],
      preOpenKeys: ['basic'],
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );
    const router = useRouter()
    watch (
        () => state.selectedKeys,
        (val, oldVal) => {
            state.selectedKeys = val
            
        }
    )
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    return {
      ...toRefs(state),
      toggleCollapsed,
      UserRoleALL,
      UserRoleOperator,
      UserRoleDirectorManager,
      UserRoleDirector,
      UserRoleBlogger,
      UserRoleAuditor,
      UserRoleCaiwu,
    };
  },
});
</script>